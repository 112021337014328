import * as echarts from 'echarts'

export default {
  render(h) {
    return h('div', {
      ref: 'main',
      style: {
        width: '100%',
        height: this.height,
      },
    })
  },
  props: {
    temp: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: '300px',
    },
  },
  watch: {
    temp: {
      handler() {
        this.$nextTick(() => {
          this.chart = echarts.init(this.$refs.main)
          this.option.xAxis.data = this.temp.map((item) => item.minute)
          this.option.series[0].data = this.temp.map((item) => ({
            name: item.day,
            value: item.temp,
          }))
          this.setOption()
          this.chart.hideLoading()
        })
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      chart: '',
      option: {
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            return (
              params[0].data.name +
              ' ' +
              params[0].axisValue +
              '<br/>' +
              params[0].marker +
              (params[0].value !== null ? params[0].value + '℃' : '')
            )
          },
        },
        dataZoom: [
          {
            type: 'slider',
            show: false,
          },
        ],
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            interval: 239,
          },
        },
        yAxis: {
          type: 'value',
          name: '（℃）',
          nameTextStyle: {
            padding: [0, 0, 0, 30],
          },
          min: 30,
          max: 100,
        },
        grid: {
          left: '9%',
          right: '9%',
          top: 40,
          bottom: 20,
        },
        series: [
          {
            data: [],
            type: 'line',
            smooth: true,
            markLine: {
              symbol: 'none', //去掉警戒线最后面的箭头
              label: {
                position: 'end', //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                formatter: '90℃',
              },
              data: [
                {
                  silent: false, //鼠标悬停事件  true没有，false有
                  lineStyle: {
                    //警戒线的样式  ，虚实  颜色
                    type: 'dashed',
                    color: 'rgba(238, 99, 99)',
                  },
                  yAxis: '90', //警戒线在y轴的坐标
                },
              ],
            },
          },
        ],
      },
    }
  },
  methods: {
    setOption() {
      this.chart.setOption(this.option)
    },
  },
}
