<template>
  <div class="app-container">
    <header class="flex middle">
      <h1 class="flex-1">CUE温度监控表</h1>
      <div class="flex-1">
        <span>选择日期：</span>
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="至"
          style="width: 250px"
          :clearable="false"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="flex-1">
        <el-input v-model="keyword" style="width: 300px" @change="searchList" placeholder="请输入盒子Mac、端口号检索">
          <el-button slot="append" @click="searchList" icon="el-icon-search"></el-button>
        </el-input>
      </div>
    </header>
    <div class="content">
      <section v-if="total">
        <div class="chart" v-for="(item, index) in tempList" :key="index">
          <div class="title flex between">
            <span>超温时长：{{ formatTime(item.minuteCount) }}</span>
            <i class="fa fa-expand hand" aria-hidden="true" @click="openDialog(item)"></i>
          </div>
          <div class="echarts">
            <e-charts ref="echarts" :temp="singlePageTempData[index]"></e-charts>
          </div>
          <div class="detail">
            <p>客户名称：{{ item.customerName }}</p>
            <p>门店名称：{{ item.shopName }}</p>
            <p>盒子Mac：{{ item.mac }}</p>
            <p>端口号：{{ item.port }}</p>
          </div>
        </div>
      </section>
      <div class="no-data flex middle center" v-else>暂无数据</div>
    </div>
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      page-size="6"
      layout="total, prev, pager, next"
      :total="total"
      v-show="total"
    >
    </el-pagination>
    <el-dialog :visible.sync="dialogVisible" width="90%">
      <span class="flex end">超温时长：{{ formatTime(dialogInfo.minuteCount) }}</span>
      <e-charts ref="dialogChart" :temp="singleTempData" height="450px"></e-charts>
      <div class="info flex">
        <span class="flex-1">客户名称：{{ dialogInfo.customerName }}</span>
        <span class="flex-1">门店名称：{{ dialogInfo.shopName }}</span>
      </div>
      <div class="info flex">
        <span class="flex-1">盒子Mac：{{ dialogInfo.mac }}</span>
        <span class="flex-1">端口号：{{ dialogInfo.port }}</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ECharts from './Echarts'
import moment from 'moment'
import { getCpuTempList, queryCpuDetail } from 'src/api/legacy'

export default {
  components: {
    ECharts,
  },
  data() {
    let _minTime = null
    let _maxTime = null
    return {
      date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      keyword: '',
      pickerOptions: {
        onPick(time) {
          // 如果选择了只选择了一个时间
          if (!time.maxDate) {
            let timeRange = 2 * 24 * 60 * 60 * 1000 // 2天
            _minTime = time.minDate.getTime() - timeRange // 最小时间
            _maxTime = time.minDate.getTime() + timeRange // 最大时间
            // 如果选了两个时间，那就清空本次范围判断数据，以备重选
          } else {
            _minTime = _maxTime = null
          }
        },
        disabledDate(time) {
          let month = 30 * 24 * 60 * 60 * 1000
          if (_minTime && _maxTime) {
            return (
              time.getTime() < _minTime ||
              time.getTime() > _maxTime ||
              time.getTime() > Date.now() ||
              time.getTime() < Date.now() - month
            )
          } else {
            return time.getTime() > Date.now() || time.getTime() < Date.now() - month
          }
        },
      },
      dialogVisible: false,
      totalList: [],
      currentPage: 1,
      total: 1,
      dialogInfo: {},
      xAxisData: [],
      tempData: [],
      tempDataList: [],
      singlePageTempData: [],
      singleTempData: [],
    }
  },
  watch: {
    date: {
      handler() {
        this.getTempData()
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    tempList() {
      return this.totalList.slice((this.currentPage - 1) * 6, this.currentPage * 6)
    },
  },
  methods: {
    formatTime(val) {
      return val > 60 ? Math.floor(val / 60) + '小时' + (val % 60) + '分钟' : val + '分钟'
    },
    // 获取温度数据
    getTempData() {
      Promise.all([this.getList({})]).then((res) => {
        this.totalList = res[0].data
        this.total = this.totalList.length
        this.getTempDataList()
      })
    },
    // 获取温度列表数据
    getTempDataList() {
      setTimeout(() => {
        for (const v of this.$refs.echarts) {
          const intervalDay =
            (new Date(this.date[1]).getTime() - new Date(this.date[0]).getTime()) / (24 * 60 * 60 * 1000)
          v.option.xAxis.axisLabel.interval = intervalDay === 0 ? 239 : intervalDay === 1 ? 479 : 719
          v.chart.showLoading()
        }
      })
      let list = []
      this.tempList.forEach((item) => {
        list.push(this.getSingleTempData(item))
      })
      Promise.all(list).then((res) => {
        this.singlePageTempData = res
      })
    },
    getList(item) {
      const params = {
        begin: this.date[0].replace(/-/g, ''),
        end: this.date[1].replace(/-/g, ''),
        keyword: item.mac || this.keyword,
      }
      return getCpuTempList(params)
    },
    // 获取单个温度数据
    getSingleTempData(item) {
      return new Promise((resolve) => {
        const params = {
          begin: this.date[0].replace(/-/g, ''),
          end: this.date[1].replace(/-/g, ''),
          mac: item.mac,
        }
        queryCpuDetail(params).then((res) => {
          resolve(res.data)
        })
      })
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getTempDataList()
    },
    async openDialog(item) {
      const info = await this.getList(item)
      this.dialogInfo = info.data[0]
      this.dialogVisible = true
      setTimeout(() => {
        const intervalDay =
          (new Date(this.date[1]).getTime() - new Date(this.date[0]).getTime()) / (24 * 60 * 60 * 1000)
        this.$refs.dialogChart.option.xAxis.axisLabel.interval = intervalDay === 0 ? 119 : intervalDay === 1 ? 239 : 359
        this.$refs.dialogChart.option.yAxis.name = 'CPU温度曲线（℃）'
        this.$refs.dialogChart.option.grid.left = '5%'
        this.$refs.dialogChart.option.grid.right = '5%'
        this.$refs.dialogChart.option.dataZoom[0].show = true
        this.$refs.dialogChart.setOption()
        // this.$refs.dialogChart.chart.resize({height: 400})
      }, 500)
      setTimeout(() => {
        this.$refs.dialogChart.chart.showLoading()
      })
      this.singleTempData = await this.getSingleTempData(item)
    },
    //搜索列表
    searchList() {
      this.getTempData()
    },
  },
}
</script>

<style lang="less" scoped>
body {
  background: #eee;
}
.containerX .contentX {
  padding-left: 10px;
  padding-right: 10px;
}
header {
  padding: 0px 10px 20px;
  background: #fff;
  margin: 10px 0;
}
.flex {
  display: flex;
}
.middle {
  align-items: center;
}
.between {
  justify-content: space-between;
}
.center {
  justify-content: center;
}
.end {
  justify-content: flex-end;
}
h1 {
  font-weight: bold;
  font-size: 16px;
}
.flex-1 {
  flex: 1;
}
.chart {
  background: #fff;
  width: 100%;
  font-size: 14px;
}
.chart .title {
  padding: 10px;
  border-bottom: 1px solid #eee;
}
.chart .detail {
  padding: 10px 40px;
  line-height: 25px;
}
.detail p {
  font-size: 14px;
}
.hand {
  cursor: pointer;
}
.content section {
  display: grid;
  grid-template-columns: repeat(3, 33%);
  grid-column-gap: 0.5%;
  grid-row-gap: 10px;
}
.echarts {
  padding: 0 15px;
}
.info {
  margin: 10px 0;
  padding: 0 50px;
}
.el-dialog__body {
  padding: 10px 20px;
}
.el-pagination {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}
.el-pagination .btn-next,
.el-pagination .btn-prev {
  background: transparent;
}
.el-pager li {
  background: transparent;
}
.el-pagination button:disabled {
  background: transparent;
}
/* .el-pager li.active {
  background: #fff;
} */
/* .el-dialog__body {
  height: 60vh;
} */
.no-data {
  height: calc(100vh - 200px);
  font-size: 18px;
}
</style>